import { css } from '@emotion/react'
import { Fragment } from 'react'

import {
  LightboxVideo,
  NewsArticle,
} from '@/features/article-templates'
import { FormArticle } from '@/features/forms'
import { TeamMemberArticle } from '@/features/team-member'

export type LightboxContentData =
  | Queries.TeamMemberArticleFragment
  | Queries.FormArticleFragment
  | Queries.ZohoFormArticleFragment
  | Queries.NewsArticleFragment
  | Queries.LightboxVideoFragment

interface Props {
  data?: LightboxContentData | null
}
const LightboxContent = ({ data }: Props): JSX.Element => {
  const style = css`
    background: #fff;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  `
  switch (data?.__typename) {
    case 'DatoCmsTeamMember':
      return (
        <TeamMemberArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsForm':
    case 'DatoCmsZohoForm':
      return (
        <FormArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsNewsArticle':
      return (
        <NewsArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsLightboxVideo':
      return (
        <LightboxVideo
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    default:
      return <Fragment />
  }
}

export default LightboxContent
