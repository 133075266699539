import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fragment, ReactElement } from 'react'

import GlobalStyles from '@/theme/GlobalStyles'

import { useNavContext } from '../../contexts/navContext'
import CtaBar from './CtaBar'
import Footer from './Footer'
import Nav from './Nav'

type Props = {
  children: ReactElement
  // pageProps: PageProps
}

const Layout = ({ children }: Props): JSX.Element => {
  const { mobileNavIsOpen } = useNavContext()
  const { datoCmsNav } = useStaticQuery<Queries.LayoutQuery>(graphql`
    query Layout {
      datoCmsNav {
        breakpoint
      }
    }
  `)
  const styles = {
    main: css`
      overflow: hidden;
      transition: opacity 300ms ease;
      ${mobileNavIsOpen &&
      css`
        @media (max-width: ${datoCmsNav?.breakpoint}px) {
          opacity: 0.2;
          filter: blur(0.5rem);
        }
      `}
    `,
  }
  return (
    <Fragment>
      <GlobalStyles />
      <Nav />
      <main css={styles.main}>{children}</main>
      {/* {pageProps.location.pathname !== '/' && <CtaBar />} */}
      <CtaBar />
      <Footer />
      <div id="lightbox-container" />
    </Fragment>
  )
}

export default Layout
