import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import {
  ComponentPropsWithoutRef,
  useLayoutEffect,
  useRef,
} from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: Queries.ZohoFormFragment | null
}

export const ZohoForm = ({ data, ...props }: Props): JSX.Element => {
  const dangerousContainerRef = useRef<HTMLDivElement | null>(null)
  const markup = `${data?.embedCode}`

  useLayoutEffect(() => {
    // Dangerously inject a script to be executed in the code. 🤞
    const range = document.createRange()
    if (dangerousContainerRef.current) {
      range.selectNode(dangerousContainerRef.current)
      const documentFragment = range.createContextualFragment(markup)

      // Inject the markup, triggering a re-run!
      dangerousContainerRef.current.innerHTML = ''
      dangerousContainerRef.current.append(documentFragment)
    }
  }, [markup])

  const styles = {
    wrapper: css`
      position: relative;
      display: grid;
      overflow: hidden;
      width: auto;
      height: auto;
      transition: all 300ms ease;
    `,
    form: css`
      position: relative;
      grid-area: 1 / 1 / 2 / 2;
      align-self: flex-start;
      display: flex;
      flex-wrap: wrap;
      --gap: 1em;
      gap: var(--gap);
      align-items: flex-start;
      justify-content: flex-start;
      opacity: 1;
      transition:
        opacity 200ms ease-out,
        transform 300ms ease-out;
      z-index: 1;
      > input[type='hidden'] {
        display: none;
      }
      > div {
        flex: 1;
      }
    `,
  }

  return (
    <div
      css={styles.wrapper}
      {...props}
    >
      <div
        css={styles.form}
        ref={dangerousContainerRef}
        dangerouslySetInnerHTML={{ __html: markup }}
      />
    </div>
  )
}

export const ZohoFormFragment = graphql`
  fragment ZohoForm on DatoCmsZohoForm {
    __typename
    id: originalId
    embedCode
  }
`
