import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

export type ArticleLayoutOptions = 'LIGHTBOX' | 'PAGE'

interface Props extends ComponentPropsWithoutRef<'article'> {
  hero?: ReactNode | null
  layout: ArticleLayoutOptions
}

const ArticleContainer = ({
  hero,
  layout,
  children,
  ...props
}: Props): JSX.Element => {
  const styles = {
    article: css`
      position: relative;
      box-sizing: border-box;
      display: grid;
      background: #fff;
      ${mq().s} {
        grid-template-columns: 1fr;
      }
      ${layout === 'PAGE' &&
      css`
        --border-width: 0px;
        --grid-w: calc(100vw - 2 * var(--border-width));
        border: var(--border-width) solid ${colors.blue};
        border-top-width: 0;
      `}
      ${mq().s} {
        --margin: calc(var(--gtr-36) + var(--gtr-12));
        --border-width: 0px;
      }
    `,
    content: css`
      padding: var(--row-72) 0 var(--row-108);
      display: grid;
      ${layout === 'PAGE' &&
      css`
        padding: var(--row-108) 0;
      `}
      ${hero &&
      css`
        padding-top: var(--row-36);
      `}
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      {hero}
      <section css={styles.content}>{children}</section>
    </article>
  )
}

export default ArticleContainer
