import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { LightboxLink } from '@/features/lightbox'

import DatoLinkIcon from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Queries.FormLinkFragment | null
  showIcon?: boolean
}

const FormLink = ({ data, showIcon, ...props }: Props): JSX.Element => {
  return (
    <LightboxLink
      data={data?.form}
      {...props}
    >
      <span>{data?.linkText}</span>
      {showIcon && <DatoLinkIcon iconType="LIGHTBOX" />}
    </LightboxLink>
  )
}

export const FormLinkFragment = graphql`
  fragment FormLink on DatoCmsFormLink {
    __typename
    id: originalId
    linkText
    form {
      __typename
      ... on DatoCmsForm {
        ...FormArticle
      }
      ... on DatoCmsZohoForm {
        ...ZohoFormArticle
      }
    }
  }
`

export default FormLink
