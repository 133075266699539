import { css } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import { bezier } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  images: ReactNode[] | undefined
  duration?: number
}

export const FadingCarousel = ({
  images,
  duration = 4000,
  ...props
}: Props): JSX.Element => {
  const length = images?.length || 0
  const timer = useRef<ReturnType<typeof setInterval> | undefined>()
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    timer.current = setInterval(() => {
      setActiveIndex(prev => {
        if (prev < length - 1) {
          return prev + 1
        } else {
          return 0
        }
      })
    }, duration)
    return () => {
      clearInterval(timer.current)
    }
  }, [duration, length])
  const styles = {
    container: css`
      display: grid;
    `,
    image: css`
      position: relative;
      grid-area: 1 / 1 / 2 / 2;
      opacity: 0;
      transition: opacity 500ms ${bezier.easeOut};
    `,
    activeImage: css`
      opacity: 1;
    `,
  }
  return (
    <div
      css={styles.container}
      data-active-index={activeIndex}
      {...props}
    >
      {images?.map((image, i) => (
        <div
          css={[styles.image, activeIndex === i && styles.activeImage]}
          key={i}
        >
          {image}
        </div>
      ))}
    </div>
  )
}
