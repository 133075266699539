export const breakpoints = {
  l: 1440,
  ls: 1260,
  ml: 1080,
  m: 900,
  ms: 720,
  s: 540,
}

export const colors = {
  rose: '#DA5E6D',
  roseLight: '#DD7A86',
  roseLighter: '#ffbbc3',
  cream: '#FBF0E5',
  peri: '#597FBA',
  periLight: '#84ade9',
  periLighter: '#9cc3ffe9',
  blue: '#4E7EA2',
  blueMid: '#44759b',
  blueMidDark: '#296490',
  blueMidLight: '#5388b0',
  blueLight: '#6792b3',
  blueLighter: '#91b3ce',
  blueExtraLight: '#D7E7FF',
  blueExtremelyLight: '#E7F1FF',
  blueDark: '#215278',
  blueDarker: '#0b3a5e',
  blueExtraDark: '#0a2438',
  gray97: 'hsl(191, 5%, 97%)',
  gray95: 'hsl(191, 5%, 95%)',
  gray90: 'hsl(191, 5%, 90%)',
  gray85: 'hsl(191, 5%, 85%)',
  gray75: 'hsl(191, 5%, 75%)',
  gray60: 'hsl(191, 5%, 60%)',
  gray40: 'hsl(191, 6%, 40%)',
  gray30: 'hsl(191, 8%, 30%)',
  gray20: 'hsl(191, 10%, 20%)',
}
