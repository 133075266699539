import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

type Props = ComponentPropsWithoutRef<'svg'>

export const LogoStacked = ({ ...props }: Props): JSX.Element => {
  const styles = {
    svg: css`
      path {
        fill: #fff;
      }
    `,
  }
  return (
    <svg
      viewBox="0 0 252 126"
      fill="none"
      css={styles.svg}
      {...props}
    >
      <path d="M119.32,67.1h-22.11v-17.83c0-4.41,3.57-7.98,7.98-7.98h14.13v25.82Z" />
      <path d="M119.32,8.75h-22.11v17.83c0,4.41,3.57,7.98,7.98,7.98h14.13V8.75Z" />
      <path d="M160.46,67.1l-15.65-21.17c-2.16-2.92-5.58-4.65-9.21-4.65h-16.27l18.58,25.82h22.55Z" />
      <path d="M160.46,8.75l-15.65,21.17c-2.16,2.92-5.58,4.65-9.21,4.65h-16.27l18.58-25.82h22.55Z" />
      <path d="M13.21,110.45h-5.81v-.14c.14-.61.78-2.82.78-4.93v-14.35c0-2.14-.65-4.32-.78-4.96v-.1h5.81v.1c-.14.65-.78,2.82-.78,4.96v14.35c0,2.11.65,4.32.78,4.93v.14ZM20.11,106.81l-7.04-8.74c-.07-.1-.1-.14.03-.24l.88-.85,5.85-5.64c2.24-2.14,2.31-4.45,2.04-5.34v-.17h5.92v.14c-.95.71-1.97,1.63-3.2,2.75l-7.82,7.48,8.6,10.67c1.22,1.6,2.82,3.2,3.13,3.43v.14h-6.6c-.34-.34-.71-2.11-1.8-3.64Z" />
      <path d="M36.8,110.45h-5.58v-.14c.14-.61.78-2.82.78-4.93v-14.69c0-2.11-.65-4.22-.88-4.83l-.03-.1.78-.14,4.15-.51v20.26c0,2.11.65,4.32.78,4.93v.14Z" />
      <path d="M39.79,101.95c0-5.37,3.47-8.7,8.23-8.7,5.03,0,7.48,3.77,7.48,8.23h-11.32c.24,3.84,1.97,6.56,5.58,6.56,1.84,0,3.54-.65,5.51-2.31l.1.1c-1.02,2.52-3.64,4.93-7.34,4.93-4.25,0-8.23-3.09-8.23-8.8ZM44.15,100.66l7.27-.78c-.1-2.92-1.05-5.41-3.47-5.41-2.72,0-3.81,3.03-3.81,6.19Z" />
      <path d="M58.56,101.95c0-5.37,3.47-8.7,8.23-8.7,5.03,0,7.48,3.77,7.48,8.23h-11.32c.24,3.84,1.97,6.56,5.58,6.56,1.84,0,3.54-.65,5.51-2.31l.1.1c-1.02,2.52-3.64,4.93-7.34,4.93-4.25,0-8.23-3.09-8.23-8.8ZM62.91,100.66l7.28-.78c-.1-2.92-1.05-5.41-3.47-5.41-2.72,0-3.81,3.03-3.81,6.19Z" />
      <path d="M82.84,110.45h-5.54v-.14c.14-.61.78-2.82.78-4.93v-6.87c0-2.14-.65-4.32-.78-4.96v-.1h4.76v2.92c1.33-1.9,3.13-3.13,5.51-3.13,2.89,0,5.58,1.7,5.58,5.75v6.39c0,2.11.65,4.32.78,4.93v.14h-5.58v-.14c.14-.61.78-2.82.78-4.93v-5.88c0-2.62-1.6-3.67-3.33-3.71-1.33,0-2.55.58-3.74,1.6v7.99c0,2.11.65,4.32.78,4.93v.14Z" />
      <path d="M100.55,98.24c0-7.55,5.03-12.61,11.63-12.61,2.28,0,5.07.58,7.92,1.56v5.27h-.2c-1.63-2.45-4.28-5.44-7.34-5.44-4.15,0-7.24,4.9-7.24,11.19s3.4,11.22,7.28,11.22c2.69,0,5.41-2.65,7.31-5.47h.2v5.3c-2.62.88-5.37,1.6-8.06,1.6-6.39,0-11.49-5.13-11.49-12.61Z" />
      <path d="M122.99,101.91c0-5.64,3.88-8.67,8.53-8.67s8.6,3.03,8.6,8.67-3.98,8.84-8.6,8.84-8.53-3.2-8.53-8.84ZM135.64,101.91c0-3.88-1.36-7.45-4.11-7.45s-4.08,3.57-4.08,7.45,1.33,7.55,4.08,7.55,4.11-3.64,4.11-7.55Z" />
      <path d="M149.1,110.45h-5.54v-.14c.14-.61.78-2.82.78-4.93v-6.87c0-2.14-.65-4.32-.78-4.96v-.1h4.76v2.92c1.33-1.9,3.13-3.13,5.51-3.13,2.89,0,5.58,1.7,5.58,5.75v6.39c0,2.11.65,4.32.78,4.93v.14h-5.58v-.14c.14-.61.78-2.82.78-4.93v-5.88c0-2.62-1.6-3.67-3.33-3.71-1.33,0-2.55.58-3.74,1.6v7.99c0,2.11.65,4.32.78,4.93v.14Z" />
      <path d="M163.32,101.95c0-5.54,3.5-8.7,8.33-8.7,2.55,0,4.73.95,6.19,1.6l.03,4.56h-.14c-1.19-1.97-3.4-4.93-5.98-4.93-2.79,0-4.08,3.03-4.08,6.43.03,4.21,1.9,7.14,5.61,7.14,1.5,0,3.33-.48,5.27-2.11l.1.07c-1.05,2.55-3.6,4.76-7.14,4.76-4.22,0-8.19-2.99-8.19-8.8Z" />
      <path d="M181.03,101.95c0-5.37,3.47-8.7,8.23-8.7,5.03,0,7.48,3.77,7.48,8.23h-11.32c.24,3.84,1.97,6.56,5.58,6.56,1.84,0,3.54-.65,5.51-2.31l.1.1c-1.02,2.52-3.64,4.93-7.34,4.93-4.25,0-8.23-3.09-8.23-8.8ZM185.38,100.66l7.28-.78c-.1-2.92-1.05-5.41-3.47-5.41-2.72,0-3.81,3.03-3.81,6.19Z" />
      <path d="M204.28,108.75v3.43c0,2.11.65,4.32.78,4.93v.14h-5.58v-.14c.14-.61.78-2.82.78-4.93v-13.43c0-2.14-.65-4.25-.88-4.83l-.03-.14.78-.1,4.15-.54v2.62c1.19-1.67,2.99-2.52,4.96-2.52,3.67,0,6.66,2.92,6.66,8.46,0,6.09-3.64,9.01-7.14,9.01-1.56,0-3.2-.58-4.49-1.97ZM204.28,97.15v10.3c.85.71,1.8,1.19,2.96,1.19,2.89,0,4.32-2.99,4.32-6.8,0-3.37-1.19-6.29-4.01-6.29-1.26,0-2.31.65-3.26,1.6Z" />
      <path d="M220.47,105.72v-10.91h-2.48v-1.12c3.2-1.19,4.86-3.16,6.19-5.51h.31v5.27h4.69v1.36h-4.69v10.47c0,1.87.92,2.75,2.41,2.75.75,0,1.8-.2,2.89-.88-.85,1.94-2.52,3.6-5.03,3.6-2.72,0-4.28-1.94-4.28-5.03Z" />
      <path d="M231.66,109.19v-4.25h.14c1.84,2.01,4.08,4.45,6.63,4.45,1.46,0,2.96-.78,2.96-2.48s-1.63-2.35-4.42-3.33c-3.47-1.19-5.58-2.62-5.58-5.34,0-3.16,2.79-5,6.63-5,1.63,0,3.84.41,5.95,1.33v4.01h-.14c-1.77-2.11-3.84-4.08-6.15-4.08-1.5,0-2.92.78-2.92,2.24,0,1.7,1.84,2.24,4.56,3.16,3.77,1.29,5.3,2.89,5.3,5.44,0,3.71-3.09,5.37-6.46,5.37-1.67,0-3.91-.44-6.49-1.53Z" />
    </svg>
  )
}
