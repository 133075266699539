import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoStructuredText } from '@/features/common-blocks'
import { colors } from '@/theme/variables'

import Form from '../Form'
import { ZohoForm } from '../ZohoForm/ZohoForm'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?:
    | Queries.FormArticleFragment
    | Queries.ZohoFormArticleFragment
    | null
  layout: 'LIGHTBOX' | 'PAGE'
}

const FormArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const styles = {
    article: css`
      ${layout === 'LIGHTBOX' &&
      css`
        padding: var(--row-72) var(--margin);
      `}
      ${layout === 'PAGE' &&
      css`
        padding: var(--row-108) var(--margin);
        background: #fff;
      `}
    `,
    heading: css`
      font-size: var(--fs-60);
      color: ${colors.blueDark};
      margin: 0 0 0.5em;
      max-width: 24ch;
    `,
    intro: css`
      max-width: 85ch;
    `,
    form: css`
      margin-top: 3em;
      max-width: 85ch;
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      <h1 css={styles.heading}>{data?.heading}</h1>
      <div css={styles.intro}>
        <DatoStructuredText data={data?.intro} />
      </div>
      {data?.__typename === 'DatoCmsForm' && (
        <Form
          css={styles.form}
          data={data}
        />
      )}
      {data?.__typename === 'DatoCmsZohoForm' && (
        <ZohoForm
          css={styles.form}
          data={data}
        />
      )}
    </article>
  )
}

export const FormArticleFragment = graphql`
  fragment FormArticle on DatoCmsForm {
    ...Form
    heading
    intro {
      value
    }
    slug
    seoMetaTags {
      tags
    }
  }
  fragment ZohoFormArticle on DatoCmsZohoForm {
    ...ZohoForm
    heading
    intro {
      value
    }
    slug
    seoMetaTags {
      tags
    }
  }
`

export default FormArticle
