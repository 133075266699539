import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

type Props = ComponentPropsWithoutRef<'svg'>

export const LogoHorizontal = ({ ...props }: Props): JSX.Element => {
  const styles = {
    svg: css`
      path {
        fill: #fff;
      }
    `,
  }
  return (
    <svg
      viewBox="0 0 252 72"
      fill="none"
      css={styles.svg}
      {...props}
    >
      <path d="M33.37,61.95H11.26v-17.83c0-4.41,3.57-7.98,7.98-7.98h14.13v25.82Z" />
      <path d="M33.37,3.61H11.26v17.83c0,4.41,3.57,7.98,7.98,7.98h14.13V3.61Z" />
      <path d="M74.51,61.95l-15.65-21.17c-2.16-2.92-5.58-4.65-9.21-4.65h-16.27l18.58,25.82h22.55Z" />
      <path d="M74.51,3.61l-15.65,21.17c-2.16,2.92-5.58,4.65-9.21,4.65h-16.27L51.95,3.61h22.55Z" />
      <path d="M95.87,29.48h-6.16v-.14c.14-.65.83-2.99.83-5.22v-15.19c0-2.27-.68-4.57-.83-5.25v-.11h6.16v.11c-.14.68-.83,2.99-.83,5.25v15.19c0,2.23.68,4.57.83,5.22v.14ZM103.18,25.63l-7.45-9.25c-.07-.11-.11-.14.04-.25l.94-.9,6.19-5.98c2.38-2.27,2.45-4.72,2.16-5.65v-.18h6.26v.14c-1.01.76-2.09,1.73-3.38,2.92l-8.28,7.92,9.11,11.3c1.29,1.69,2.99,3.38,3.31,3.64v.14h-6.98c-.36-.36-.76-2.23-1.91-3.85Z" />
      <path d="M120.86,29.48h-5.9v-.14c.14-.65.83-2.99.83-5.22v-15.55c0-2.23-.68-4.46-.94-5.11l-.04-.11.83-.14,4.39-.54v21.45c0,2.23.68,4.57.83,5.22v.14Z" />
      <path d="M124.02,20.48c0-5.69,3.67-9.21,8.71-9.21,5.33,0,7.92,4,7.92,8.71h-11.99c.25,4.07,2.09,6.95,5.9,6.95,1.94,0,3.74-.68,5.83-2.45l.11.11c-1.08,2.66-3.85,5.22-7.78,5.22-4.5,0-8.71-3.28-8.71-9.32ZM128.63,19.11l7.7-.83c-.11-3.1-1.12-5.72-3.67-5.72-2.88,0-4.03,3.2-4.03,6.55Z" />
      <path d="M143.9,20.48c0-5.69,3.67-9.21,8.71-9.21,5.33,0,7.92,4,7.92,8.71h-11.99c.25,4.07,2.09,6.95,5.9,6.95,1.94,0,3.74-.68,5.83-2.45l.11.11c-1.08,2.66-3.85,5.22-7.78,5.22-4.5,0-8.71-3.28-8.71-9.32ZM148.5,19.11l7.7-.83c-.11-3.1-1.12-5.72-3.67-5.72-2.88,0-4.03,3.2-4.03,6.55Z" />
      <path d="M169.6,29.48h-5.87v-.14c.14-.65.83-2.99.83-5.22v-7.27c0-2.27-.68-4.57-.83-5.25v-.11h5.04v3.1c1.4-2.02,3.31-3.31,5.83-3.31,3.06,0,5.9,1.8,5.9,6.08v6.77c0,2.23.68,4.57.83,5.22v.14h-5.9v-.14c.14-.65.83-2.99.83-5.22v-6.23c0-2.77-1.69-3.89-3.53-3.92-1.4,0-2.7.61-3.96,1.69v8.46c0,2.23.68,4.57.83,5.22v.14Z" />
      <path d="M88.21,49.21c0-7.99,5.33-13.35,12.31-13.35,2.41,0,5.36.61,8.39,1.66v5.58h-.22c-1.73-2.59-4.54-5.76-7.78-5.76-4.39,0-7.67,5.18-7.67,11.84s3.6,11.88,7.7,11.88c2.84,0,5.72-2.81,7.74-5.79h.22v5.62c-2.77.94-5.69,1.69-8.53,1.69-6.77,0-12.17-5.43-12.17-13.35Z" />
      <path d="M111.97,53.1c0-5.97,4.1-9.18,9.04-9.18s9.11,3.2,9.11,9.18-4.21,9.36-9.11,9.36-9.04-3.38-9.04-9.36ZM125.36,53.1c0-4.1-1.44-7.88-4.36-7.88s-4.32,3.78-4.32,7.88,1.4,7.99,4.32,7.99,4.36-3.85,4.36-7.99Z" />
      <path d="M139.62,62.14h-5.87v-.14c.14-.65.83-2.99.83-5.22v-7.27c0-2.27-.68-4.57-.83-5.25v-.11h5.04v3.1c1.4-2.02,3.31-3.31,5.83-3.31,3.06,0,5.9,1.8,5.9,6.08v6.77c0,2.23.68,4.57.83,5.22v.14h-5.9v-.14c.14-.65.83-2.99.83-5.22v-6.23c0-2.77-1.69-3.89-3.53-3.92-1.4,0-2.7.61-3.96,1.69v8.46c0,2.23.68,4.57.83,5.22v.14Z" />
      <path d="M154.67,53.14c0-5.87,3.71-9.21,8.82-9.21,2.7,0,5,1.01,6.55,1.69l.04,4.82h-.14c-1.26-2.09-3.6-5.22-6.33-5.22-2.95,0-4.32,3.2-4.32,6.8.04,4.46,2.02,7.56,5.94,7.56,1.58,0,3.53-.5,5.58-2.23l.11.07c-1.12,2.7-3.82,5.04-7.56,5.04-4.46,0-8.68-3.17-8.68-9.32Z" />
      <path d="M173.42,53.14c0-5.69,3.67-9.21,8.71-9.21,5.33,0,7.92,4,7.92,8.71h-11.99c.25,4.07,2.09,6.95,5.9,6.95,1.94,0,3.74-.68,5.83-2.45l.11.11c-1.08,2.66-3.85,5.22-7.78,5.22-4.5,0-8.71-3.28-8.71-9.32ZM178.03,51.77l7.7-.83c-.11-3.1-1.12-5.72-3.67-5.72-2.88,0-4.03,3.2-4.03,6.55Z" />
      <path d="M198.05,60.34v3.64c0,2.23.68,4.57.83,5.22v.14h-5.9v-.14c.14-.65.83-2.99.83-5.22v-14.22c0-2.27-.68-4.5-.94-5.11l-.04-.14.83-.11,4.39-.58v2.77c1.26-1.76,3.17-2.66,5.26-2.66,3.89,0,7.05,3.1,7.05,8.96,0,6.44-3.85,9.54-7.56,9.54-1.66,0-3.38-.61-4.75-2.09ZM198.05,48.06v10.91c.9.76,1.91,1.26,3.13,1.26,3.06,0,4.57-3.17,4.57-7.2,0-3.56-1.26-6.66-4.25-6.66-1.33,0-2.45.68-3.46,1.69Z" />
      <path d="M215.18,57.13v-11.55h-2.63v-1.19c3.38-1.26,5.15-3.35,6.55-5.83h.32v5.58h4.97v1.44h-4.97v11.09c0,1.98.97,2.92,2.56,2.92.79,0,1.91-.22,3.06-.94-.9,2.05-2.66,3.82-5.33,3.82-2.88,0-4.54-2.05-4.54-5.33Z" />
      <path d="M227.03,60.8v-4.5h.14c1.94,2.12,4.32,4.71,7.02,4.71,1.55,0,3.13-.83,3.13-2.63s-1.73-2.48-4.68-3.53c-3.67-1.26-5.9-2.77-5.9-5.65,0-3.35,2.95-5.29,7.02-5.29,1.73,0,4.07.43,6.3,1.4v4.25h-.14c-1.87-2.23-4.07-4.32-6.51-4.32-1.58,0-3.1.83-3.1,2.38,0,1.8,1.94,2.38,4.82,3.35,4,1.37,5.62,3.06,5.62,5.76,0,3.92-3.28,5.69-6.84,5.69-1.76,0-4.14-.47-6.88-1.62Z" />
    </svg>
  )
}
