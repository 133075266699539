import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoLink } from '@/features/links'
import { colors } from '@/theme/variables'

type Props = ComponentPropsWithoutRef<'section'>

const CtaBar = ({ ...props }: Props): JSX.Element => {
  const { datoCmsCtaBar } = useStaticQuery<Queries.CtaBarQuery>(graphql`
    query CtaBar {
      datoCmsCtaBar {
        id: originalId
        __typename
        leadIn
        cta {
          ... on DatoCmsFormLink {
            ...FormLink
          }
          ... on DatoCmsPageLink {
            ...PageLink
          }
          ... on DatoCmsDocumentLink {
            ...DocumentLink
          }
          ... on DatoCmsExternalLink {
            ...ExternalLink
          }
        }
      }
    }
  `)
  const styles = {
    section: css`
      position: relative;
      z-index: 1;
      background-color: ${colors.gray95};
      padding: 1em var(--margin);
      text-align: center;
      color: ${colors.blue};
      border-top: 0.5rem solid ${colors.blue};
    `,
    heading: css`
      font-size: var(--fs-30);
      margin: 0;
    `,
    leadIn: css`
      margin: 0.25em 0;
    `,
    link: css`
      display: inline-block;
      margin: 0;
      padding: 0.25em;
      color: ${colors.blueDark};
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 0.25em;
      @media (hover: hover) {
        &:hover {
          color: ${colors.rose};
        }
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <h2 css={styles.heading}>
        <span css={styles.leadIn}>{datoCmsCtaBar?.leadIn}</span>{' '}
        {datoCmsCtaBar?.cta?.map(cta => (
          <DatoLink
            data={cta}
            key={cta?.id}
            styleVariant="LOWERCASE"
            css={styles.link}
          />
        ))}
      </h2>
    </section>
  )
}

export default CtaBar
