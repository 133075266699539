import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoStructuredText } from '../DatoStructuredText'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.BodyTextBlockFragment | null
  // disableLightboxLinks?: boolean
}

const BodyTextBlock = ({
  data,
  // disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  return (
    <div {...props}>
      <DatoStructuredText data={data?.body} />
    </div>
  )
}

export const BodyTextBlockFragment = graphql`
  fragment BodyTextBlock on DatoCmsBodyTextBlock {
    __typename
    id: originalId
    body {
      value
    }
  }
`

export default BodyTextBlock
